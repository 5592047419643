import StoreLayout from "../components/layout/store-layout";
import React, {ReactElement, useEffect, useState} from "react";
import {NextPageWithLayout} from "./_app";
import Head from "next/head";
import {productsTransformer} from "../transformers/getProducts";
import {EventList} from "../components/event-list";
import {Product} from "../models/models";
import {get} from "./[slug]";

const Page: NextPageWithLayout<{products: Product[]}> = (props) => {

    return <>
        <Head>
            <title>My Comedy Tickets: Made by Comedians for Comedy Lovers</title>
            <meta property="og:image" content="https://i.imgur.com/VuRvaoH.png"/>
            <meta name="description" content="Comedy tickets at low prices with profits going directly to the performers. "/>
        </Head>
        <EventList id={"home-page-event-list"} name={"Home Page Event List"} products={props.products}/>
    </>
}

export default Page

Page.getLayout = function getLayout(page: ReactElement) {
    return <StoreLayout><div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">{page}</div></div></StoreLayout>
}

export async function getStaticProps() {
    if (process.env.NEXT_PUBLIC_CATEGORY_HANDLE) {
        const json = await get(`/seo/slugs/${process.env.NEXT_PUBLIC_CATEGORY_HANDLE}/products`)
        const products: Product[] = productsTransformer({products: json.items})
        return {
            props: { products },
        }
    } else {
        const json = await get(`/seo/events`)
        const products: Product[] = productsTransformer(json)
        return {
            props: { products },
        }
    }
}