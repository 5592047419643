import React from "react";
import {toShowtime} from "../utils/date";
import {formatMoneyAmount} from "../utils/price";
import {MoneyAmount, ProductVariant} from "@medusajs/medusa";
import StockIndicator from "./stock-indicator";
import {isProductInStock} from "../utils/cart";
import {Product} from "../models/models";
import {CityName} from "./city-name";
import Image from "next/image";
import {createSelectItemPayload, sendGaEvent} from "../utils/google-analytics";

type EventListProps = {
    products: Product[]
    showPast?: boolean
    id?: string
    name?: string
}
export function EventList({products, showPast = false, id, name}: EventListProps) {

    const consolidatedShowtimes = (product: Product) => {
        const showtimes = (showPast ? product.inactiveVariants : product.activeVariants).map(v => (
            v.options.map(o => toShowtime(o.value))
        )).flat()
        return [...new Set(showtimes)];
    }
    const firstVariant = (variants: ProductVariant[]): ProductVariant | undefined => {
        return variants && variants.length !== 0 ? variants[0] : undefined
    }

    return <div className="grid grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
        {products && products.length !== 0 ? products.map((product) => (
            <React.Fragment key={product.id}>{ (showPast ? product.inactiveVariants.length !== 0 : product.activeVariants.length !== 0) && <div
                className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
            >   <CityName name={product.venueArrangement.venue.city.name}/>
                <div className="bg-gray-200 sm:aspect-none">
                    {product.thumbnail && <Image
                        src={product.thumbnail}
                        alt={product.title}
                        className="w-auto h-auto"
                        width={800}
                        height={800}
                    />}
                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                    <h3 className="text-sm font-medium text-gray-900">
                        <a href={`/store/events/view/${product.id}`} onClick={e => sendGaEvent('select_item', createSelectItemPayload(product, id, name))}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.title}
                        </a>
                    </h3>
                     <p className="hidden md:block text-sm text-gray-500">{product.description}</p>
                    <div className="flex-1 flex flex-col justify-end">
                        {consolidatedShowtimes(product).map(v => (
                            <p key={v} className="text-xs md:text-sm italic text-gray-500">{v}</p>
                        ))}
                        <p className="text-base font-medium text-gray-900">{formatMoneyAmount(firstVariant((showPast ? product.inactiveVariants : product.activeVariants))?.prices[0] as MoneyAmount)}</p>
                    </div>
                    <StockIndicator inStock={isProductInStock(product)}/>
                </div>
            </div>}</React.Fragment>
        )) : <div>No events found.</div>}
    </div>
}