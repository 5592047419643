import {CheckIcon, ExclamationCircleIcon, XCircleIcon,} from "@heroicons/react/solid";
type StockIndicatorProps = {
    inventory_quantity?: number
    inStock?: boolean
}
export default function StockIndicator(props: StockIndicatorProps) {
    const isInStock = (props: StockIndicatorProps) => {
        return props.inStock || (props.inStock === undefined && props.inventory_quantity !== undefined && props.inventory_quantity > 5)
    }
    const isLowInStock = (props: StockIndicatorProps) => {
        return props.inStock === undefined && props.inventory_quantity !== undefined && props.inventory_quantity < 5 && props.inventory_quantity !== 0
    }
    const isOutOfStock = (props: StockIndicatorProps) => {
        return props.inStock === false || (props.inventory_quantity !== undefined && props.inventory_quantity === 0)
    }
    return <>
        {isInStock(props) && <div className="mt-6 flex items-center">
            <CheckIcon className="flex-shrink-0 w-5 h-5 text-green-500" aria-hidden="true" />
            <p className="ml-2 text-sm text-gray-500">In stock</p>
        </div>}
        {isLowInStock(props) && <div className="mt-6 flex items-center">
            <ExclamationCircleIcon className="flex-shrink-0 w-5 h-5 text-yellow-500" aria-hidden="true" />
            <p className="ml-2 text-sm text-gray-500">Only {props.inventory_quantity} left</p>
        </div>}
        {isOutOfStock(props) && <div className="mt-6 flex items-center">
            <XCircleIcon className="flex-shrink-0 w-5 h-5 text-red-500" aria-hidden="true" />
            <p className="ml-2 text-sm text-gray-500">Out of stock</p>
        </div>}
    </>
}