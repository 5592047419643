import {MoneyAmount, Region} from "@medusajs/medusa";

export const formatMoneyAmount = (moneyAmount: MoneyAmount): string => {
    return formatCurrencyAndAmount(moneyAmount.currency_code, moneyAmount.amount)
}

export const formatAmount = (currencyCode: string, amount: number): string => {
    return formatCurrencyAndAmount(currencyCode, amount)
}

export const formatRegionAmount = (region: Region | undefined, amount: number | string | undefined | null): string => {
    if (!region || !amount) {
        return "";
    }
    return formatCurrencyAndAmount(region.currency_code, parseInt(amount as string));
}

export const formatAmountOnly = (amount: number) => {
    return formatCurrencyAndAmount("", amount)
}
const formatCurrencyAndAmount = (currency_code: string, amount: number): string => {
    let prefix = "";
    if (currency_code === 'usd') {
        prefix = "US $"
    } else if (currency_code === 'cad') {
        prefix = "CAD $"
    } else if (currency_code === 'eur') {
        prefix = "€"
    }
    const moneyInSingleUnitsOfCurrency = amount / 100;
    const rounded = Math.round((moneyInSingleUnitsOfCurrency + Number.EPSILON) * 100) / 100
    return `${prefix}${rounded.toFixed(2)}`;
}